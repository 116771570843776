import React from 'react';

export const showAddress = (addr?: string) => {
  if (!addr) return '';
  return `${addr.substring(0, 6)}...${addr.slice(addr.length - 4)}`;
};

export const showPercentage = (number?: number) => {
  if (!number) return '0.0%';
  return `${Number(number).toFixed(1)}%`;
};

export const toJSTimestamp = (timestamp?: number | string | null) => {
  // if 0 Countdown will not show renderer element
  return Number(timestamp || 1) * 1000;
};

export const handleInputMaxLength = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  if ('0123456789'.indexOf(e.key) === -1) return true;
  const target = e.target as HTMLInputElement;
  if (target.value?.length >= target.maxLength) {
    e.preventDefault();
    return false;
  }
  return true;
};

export const handleInputNumberOnly = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  const regex =
    /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|\.|e)/;

  if (e.key.match(regex) || (e.ctrlKey && e.key === 'a')) return true;

  e.preventDefault();
  return false;
};

export const getImgProxySrc = (originalSrc?: string, size?: number) => {
  const proxyURL = process.env.REACT_APP_IMG_PROXY_URL;
  if (!proxyURL) return originalSrc;

  if (!originalSrc || !size) return '';
  return `${proxyURL}/no-s/rs:fill:${size}/plain/${originalSrc}`;
};
