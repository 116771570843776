import { Creator, Owner, User } from '@share/interfaces';
import { showAddress } from '@util/helper';
import { toast } from 'react-toastify';
import { useCurrency } from './useCurrency';
import { useDebouncedCallback } from 'use-debounce';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { user as userContainer } from '@state/user';
import { web3 } from '@state/web3';
import styled from '@emotion/styled';

const useHelper = () => {
  const { t, i18n } = useTranslation();
  const { isFiatCurrency } = useCurrency();
  const { addressExplorerBaseURL, chainId } = web3.useContainer();
  const { theme } = userContainer.useContainer();

  // Style
  const CopyAddressBtn = styled.button`
    margin-left: 1rem;
    padding: 0;
    border: none;
    background: none;

    :hover,
    :focus {
      border: none;
      color: var(--primary-color3);
    }

    i {
      color: var(--primary-color2);
    }
  `;

  // For showDescription only
  const [isShowMore, setIsShowMore] = useState<boolean>(false);
  const UNNAMED = t('general.unnamed');

  const copyText = useDebouncedCallback(
    (text?: string, msg?: string) => {
      const dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = text || '';

      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        dummy.contentEditable = 'true';
        dummy.readOnly = true;

        const range = document.createRange();
        range.selectNodeContents(dummy);

        const selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);
        dummy.setSelectionRange(0, 999999);
      } else {
        dummy.select();
      }

      document.execCommand('copy');
      document.body.removeChild(dummy);

      toast.info(msg);
    },
    1000,
    { leading: true, trailing: false }
  );

  const displayName = (name?: string) => {
    if (!name) return UNNAMED;
    return name?.length === 42 && name.startsWith('0x') ? UNNAMED : name;
  };

  const getUserIdentifier = (user?: User | Owner | Creator) => {
    if (user?.name && user.name !== UNNAMED) {
      return user.name;
    }
    return user?.uuid || '';
  };

  const getEventName = (type: string): string => {
    switch (type) {
      case 'SALE':
        return t('profile.SALE');
      case 'LIST':
        return t('profile.LIST');
      case 'TRANSFER':
        return t('profile.TRANSFER');
      case 'MINTED':
        return t('profile.MINTED');
      case 'BID_ACCEPT':
        return t('profile.BID_ACCEPT');
      case 'BID':
        return t('profile.BID');
      case 'CANCEL':
        return t('profile.CANCEL');
      default:
        return t('profile.WRONG');
    }
  };

  const showDescription = (description?: string | null) => {
    const desLenght = description?.length;
    if (!desLenght || desLenght <= 0) return '';
    if (desLenght < 200) return description;

    return (
      <div className="d-flex flex-column align-items-start">
        {isShowMore ? description : `${description.substring(0, 200)} ...`}
        <button
          className="sc-button loadmore style-3 mt-4"
          style={{ borderColor: 'var(--primary-color1)', padding: '0.5em 1em' }}
          type="button"
          onClick={() => setIsShowMore(!isShowMore)}
        >
          {isShowMore ? t('general.showLess') : t('general.showMore')}
        </button>
      </div>
    );
  };

  const nameToPath = (name: string, address: string) => {
    const path: string = name.replaceAll(' ', '-') || address || '';
    return path;
  };

  const displayCurrency = (
    value?: string | number,
    currencyName?: string,
    currencyDisplay?: string
  ) => {
    const digit = isFiatCurrency(currencyName, 'name') ? 2 : 6;

    // Because if currency name is null or undefined, it will be error of toLocaleString function
    // Therefore, using XXX to make sure show currency name first
    // not used real currency symbol because we support multi-network, it will a bit
    // hard to debug if somehow currencyId.name is undefined
    const safeCurrencyName = currencyName?.slice(0, 3) || 'XXX';

    const currencyString = (Number(value) || 0).toLocaleString(i18n.language, {
      style: 'currency',
      currencyDisplay: currencyDisplay || 'code',
      currency: safeCurrencyName,
      maximumFractionDigits: digit,
    });

    // Since toLocaleString currency name only allow 3 characters
    // So use this hacky way to support 4 characters currency at the moment

    return currencyName && safeCurrencyName !== currencyName
      ? currencyString.replace(safeCurrencyName.toUpperCase(), currencyName)
      : currencyString;
  };

  const showAddressLink = (addr?: string, _chainId?: number) => {
    if (!addr) return '';
    return (
      <div className="d-flex align-items-center">
        <a
          href={`${addressExplorerBaseURL(_chainId || chainId)}/${addr}`}
          rel="noreferrer"
          target="_blank"
        >
          {showAddress(addr)}
        </a>
        <CopyAddressBtn
          className="d-flex"
          type="button"
          onClick={() => {
            copyText(addr, t('toastMessage.addressCopied'));
          }}
        >
          <i className="icon-fl-file-1" />
        </CopyAddressBtn>
      </div>
    );
  };

  /**
   * Hacky way to get primary color and then adding alpha 0.5
   */
  const _primaryColor = `${getComputedStyle(document.body).getPropertyValue(
    '--primary-color'
  )}`;
  const primaryColor = useMemo(() => {
    let result = _primaryColor;
    if (/#([0-9A-F]{3})$/i.test(_primaryColor)) {
      // Convert 3-digit hexadecimal color to 6-digit hexadecimal color
      const newHexColor = _primaryColor.replace(
        /#([0-9A-F])([0-9A-F])([0-9A-F])$/i,
        '#$1$1$2$2$3$3'
      );
      result = newHexColor;
    }
    return result;
  }, [theme, _primaryColor]);

  return {
    copyText,
    primaryColor,
    displayName,
    getUserIdentifier,
    displayCurrency,
    getEventName,
    showDescription,
    nameToPath,
    showAddressLink,
  };
};

export { useHelper };
