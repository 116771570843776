import * as Sentry from '@sentry/react';
import { APIErrorStatus } from '@share/enums';
import { API_ERROR_MSG } from '@share/constant';
import {
  BidGasReport,
  BuyGasReport,
  CancelOrderArgs,
  ChangePasswordArgs,
  CheckoutBidArgs,
  CheckoutBuyArgs,
  CheckoutMintArgs,
  CollectionDetail,
  Config,
  CreateAuctionArgs,
  CreateSaleArgs,
  CurrencyData,
  FAQ,
  GetNftHistoryQueryArgs,
  GetNftListQueryArgs,
  GetSearchResultQueryArgs,
  GetUserNftQueryArgs,
  GkashBidArgs,
  GkashBuyArgs,
  GkashMintArgs,
  GkashPaymentDetail,
  HomeBanner,
  KycStatus,
  LoginData,
  MintStatus,
  NFT,
  NftDetail,
  NftEvent,
  RegisterData,
  SubmitBankKycArgs,
  SubmitIdKycArgs,
  TakeHighestBidArgs,
  UpdateProfileArgs,
  User,
  WithdrawData,
  WithdrawNftArgs,
} from '@interface/api';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { user } from '@state/user';
import { web3 } from '@state/web3';
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';

const { REACT_APP_API_BASE_URL, REACT_APP_DEFAULT_CHAINID } = process.env;

axios.defaults.baseURL = `${REACT_APP_API_BASE_URL}/api/v1`;
axios.defaults.transformResponse = (response: string) => {
  try {
    const parsedRes = JSON.parse(response);
    // External API may not follow .data practice, so return raw response if data == null
    return parsedRes.data != null ? parsedRes.data : parsedRes;
  } catch (error) {
    return response;
  }
};
const authInstance = axios.create();

/**
 *
 * @param isComponent This is a tmp solution to ensure no duplicate axios interceptors
 * if other's hook need to use this hook, then should set to false, otherwise it may
 * cause login issue.
 *
 * TODO: move this variable to global level?
 */
const useAPI = (isComponent = true) => {
  const { userData } = user.useContainer();
  const { disconnect } = web3.useContainer();
  const { t } = useTranslation();
  const location = useLocation();

  const httpErrorMsgList: Record<number, string> = {
    401: t('error.401'),
    // 404: t('error.404'), // Already use animation to handle it, no toast require
    422: t('error.422'),
    500: t('error.500'),
    504: t('error.504'),
  };

  const appErrorMsgList: Record<number, string> = {
    [APIErrorStatus.WALLET_IS_NOT_TREASURY]: t(
      'toastMessage.needTransferToTreasury'
    ),
    [APIErrorStatus.WITHDRAW_NFT_PROCESSING]: t('modal.beingTakeAway'),
    [APIErrorStatus.USER_NOT_ID_KYC]: t('toastMessage.needIdKYCFirst'),
    [APIErrorStatus.USER_NOT_EMAIL_KYC]: t('toastMessage.needEmailKYCFirst'),
    [APIErrorStatus.USER_NOT_BANK_KYC]: t('toastMessage.needBankKYCFirst'),
    [APIErrorStatus.EMAIL_CODE_NOT_MATCH]: t('toastMessage.codeError'),
    [APIErrorStatus.BALANCE_AMOUNT_NOT_ENOUGH]: t(
      'errorMsg.balance.insufficient'
    ),
    [APIErrorStatus.BALANCE_AMOUNT_MAX]: t('errorMsg.balance.belowMinimum'),
    [APIErrorStatus.ORDER_PRICE_NOT_MATCH]: t('modal.transactionFeeUpdated'),
    [APIErrorStatus.ORDER_BID_PRICE_ERROR]: t('alertMessage.hasLatestPrice'),
    [APIErrorStatus.ORDER_PRICE_ERROR]: t('modal.checkMinBid'),
    [APIErrorStatus.USER_LOGIN_INFO_ERROR]: t('toastMessage.loginError'),
    [APIErrorStatus.EMAIL_LINK_INVALID]: t('toastMessage.emailLinkInvalid'),
    [APIErrorStatus.LAUNCHPAD_PHASE_NOT_MATCH]: t(
      'toastMessage.mintTimeNotCorrect'
    ),
    [APIErrorStatus.EMAIL_ALREADY_SENT]: t(
      'toastMessage.verifyEmailAlreadySent'
    ),
  };

  // Auto fill-in all undefined chain_id and translate 'my' uuid
  const handleDefaultRequestConfig = (config: InternalAxiosRequestConfig) => {
    const autoAddData = (key: string, condition: unknown, value: unknown) => {
      const { params, data } = config;
      if (params && key in params && params[key] === condition)
        params[key] = value;
      if (data && key in data && data[key] === condition) data[key] = value;
    };
    autoAddData('chain_id', undefined, Number(REACT_APP_DEFAULT_CHAINID));
    autoAddData('uuid', 'my', userData?.user?.uuid);
    return config;
  };

  // Auto show error alert box when API status code is 4xx
  const handleGlobalResponseError = (error: AxiosError) => {
    const { response } = error;
    const httpErrorCode = Number(response?.status);
    // Display HTTP level error message
    if (httpErrorCode > 400) {
      const httpErrorMsg = httpErrorMsgList[httpErrorCode];
      if (!toast.isActive(API_ERROR_MSG) && httpErrorMsg) {
        // only show one popup at the same time
        toast.error(httpErrorMsg, { toastId: API_ERROR_MSG });
      }

      if (httpErrorCode === 401)
        disconnect('/login', { redirectTo: location.pathname });
    }

    // Display application level error message
    if (httpErrorCode === 400) {
      const data: any = response?.data;
      const appErrorCode = Number(data?.code);
      const appErrorMsg = appErrorMsgList[appErrorCode];
      // Only show one popup at the same time
      if (!toast.isActive(API_ERROR_MSG)) {
        // data.message only show in the development environment
        toast.error(
          appErrorMsg ||
            data?.message ||
            t('errorMsg.unexcepted', { code: appErrorCode }),
          {
            toastId: API_ERROR_MSG,
            autoClose: appErrorMsg || data?.message ? 5000 : 10000,
          }
        );
      }
    }

    return Promise.reject(error);
  };

  // Auto add bearer token for all private API
  const handleAuthRequestConfig = (config: InternalAxiosRequestConfig) => {
    const { headers } = config;
    if (headers && userData?.token)
      headers.Authorization = `Bearer ${userData.token}`;
    return config;
  };

  useEffect(() => {
    let defaultResInterceptor: number;
    let defaultReqInterceptor: number;
    let authResInterceptor: number;
    let authReqInterceptor: number;
    let authReqInterceptor2: number;

    if (isComponent) {
      defaultResInterceptor = axios.interceptors.response.use(
        undefined,
        handleGlobalResponseError
      );
      defaultReqInterceptor = axios.interceptors.request.use(
        handleDefaultRequestConfig
      );
      authResInterceptor = authInstance.interceptors.response.use(
        undefined,
        handleGlobalResponseError
      );
      authReqInterceptor = authInstance.interceptors.request.use(
        handleDefaultRequestConfig
      );
      authReqInterceptor2 = authInstance.interceptors.request.use(
        handleAuthRequestConfig
      );
    }
    return () => {
      if (isComponent) {
        axios.interceptors.response.eject(defaultResInterceptor);
        axios.interceptors.request.eject(defaultReqInterceptor);
        authInstance.interceptors.response.eject(authResInterceptor);
        authInstance.interceptors.request.eject(authReqInterceptor);
        authInstance.interceptors.request.eject(authReqInterceptor2);
      }
    };
  }, []);

  const privateAPI = {
    /*
      Profile
    */
    getProfile: async (chainId?: number, uuid?: string) => {
      // Backend will check is profile owner or not to return different value
      const { data } = await authInstance.post<User>('/profile/query', {
        chain_id: chainId,
        uuid,
      });
      return data;
    },
    updateProfile: async (args: UpdateProfileArgs) => {
      const { data } = await authInstance.post<User>('/profile/update', {
        name: args.name,
        bio: args.bio,
        personal_site: args.personalSite,
        social_link_facebook: args.facebookLink,
        social_link_twitter: args.twitterLink,
        social_link_discord: args.discordLink,
        language: args.language,
      });
      return data;
    },
    // Same route with isUsernameValid, but this api will use authInstance (filter current username)
    isProfileNameValid: async (name: string) => {
      const isDuplicate = (
        await authInstance.post<boolean>('/profile/check-user', {
          name,
        })
      ).data;
      return isDuplicate;
    },
    updateAvatar: async (avatar?: File) => {
      const formData = new FormData();
      if (avatar) formData.append('avatar', avatar);
      const { data } = await authInstance.post<User>(
        '/profile/update-avatar',
        formData
      );
      return data;
    },
    submitIdKyc: async (args: SubmitIdKycArgs) => {
      const formData = new FormData();
      formData.append('card_image', args.cardImage);
      formData.append('country', args.country);
      formData.append('full_name', args.fullName);
      formData.append('card_no', args.cardNumber);
      const { data } = await authInstance.post<KycStatus>(
        '/auth/kyc/id-submit',
        formData
      );
      return data;
    },
    cancelIdKyc: async () => {
      const { data } = await authInstance.post<KycStatus>(
        '/auth/kyc/id-cancel'
      );
      return data;
    },
    submitBankKyc: async (args: SubmitBankKycArgs) => {
      const formData = new FormData();
      formData.append('country', args.country);
      formData.append('bank_name', args.bankName);
      formData.append('bank_account_no', args.bankAccountNumber);
      formData.append('bank_document_image', args.cardImage);
      const { data } = await authInstance.post<KycStatus>(
        '/auth/kyc/bank-submit',
        formData
      );
      return data;
    },
    cancelBankKyc: async () => {
      const { data } = await authInstance.post<KycStatus>(
        '/auth/kyc/bank-cancel'
      );
      return data;
    },
    changePassword: async (args: ChangePasswordArgs) => {
      const { data } = await authInstance.post<any>(
        '/auth/reset-password/reset',
        {
          u: args.uuid,
          k: args.oneTimeKey,
          password: args.password,
          password_confirmation: args.password,
        }
      );
      return data;
    },
    sendResetPasswordEmail: async (email: string) => {
      const { data: isSuccess } = await authInstance.post<true>(
        '/auth/reset-password/send',
        { email }
      );
      return isSuccess;
    },
    sendVerifyAccountEmail: async (email: string) => {
      const { data: isSuccess } = await authInstance.post<true>(
        '/auth/email/verify/resend',
        { email }
      );
      return isSuccess;
    },
    verifyAccount: async (uuid: string, oneTimeKey: string) => {
      const { data: isSuccess } = await authInstance.post<true>(
        '/auth/email/verify',
        {
          u: uuid,
          k: oneTimeKey,
        }
      );
      return isSuccess;
    },
    logout: async () => {
      try {
        await authInstance.post('/auth/logout');
      } catch (error) {
        // FIXME: Have to use try catch to ensure not blocking disconnect process
        Sentry.captureException(error);
      }
    },
    /*
      Receipt
    */
    getReceiptList: async ({ pageParam }: { pageParam?: string }) => {
      const { data } = await authInstance.post<any>('/receipt/list', {
        cursor: pageParam,
      });
      return data;
    },
    getReceiptDetail: async (invoiceId: string) => {
      const { data } = await authInstance.post<any>('/receipt/query', {
        no: invoiceId,
      });
      return data;
    },
    /*
      Balance
    */
    getBalanceList: async ({ pageParam }: { pageParam?: string }) => {
      const { data } = await authInstance.post<any>('/balance/list', {
        cursor: pageParam,
      });
      return data;
    },
    getBalanceDetail: async (invoiceId: string) => {
      const { data } = await authInstance.post<any>('/balance/query', {
        no: invoiceId,
      });
      return data;
    },
    withdrawDeposit: async (amount: number, currency: string) => {
      const { data } = await authInstance.post<any>('/balance/request', {
        amount: amount * 100,
        currency,
      });
      return data;
    },
    /*
      Gas
    */
    getBuyOrderGas: async (orderId: number) => {
      const { data } = await authInstance.post<BuyGasReport>(
        '/marketplace/credit/check-buy-order',
        {
          order_id: orderId,
        }
      );
      return data;
    },
    getBidOrderGas: async (orderId: number) => {
      const { data } = await authInstance.post<BidGasReport>(
        '/marketplace/credit/check-bid-order',
        {
          order_id: orderId,
        }
      );
      return data;
    },
    /*
      Checkout Payment
    */
    processCheckoutMint: async (args: CheckoutMintArgs) => {
      const { data } = await authInstance.post<any>(
        '/launchpad/credit_mint_nft',
        {
          uuid: args.uuid,
          wallet_address: args.walletAddress,
          amount: args.amount,
          phase: args.phase,
          total_price: args.totalPrice,
          card_token: args.cardToken,
        }
      );
      return data;
    },
    processCheckoutBuy: async (args: CheckoutBuyArgs) => {
      const { data } = await authInstance.post<any>(
        '/marketplace/credit/buy-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress,
          token_id: args.tokenId,
          buyer_address: args.buyerAddress,
          total_price: args.totalPrice,
          list_id: -1, // historical reason, useless now
          card_token: args.cardToken,
        }
      );
      return data;
    },
    processCheckoutBid: async (args: CheckoutBidArgs) => {
      const { data } = await authInstance.post<any>(
        '/marketplace/credit/bid-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress,
          token_id: args.tokenId,
          price: args.bidPrice,
          total_price: args.totalPrice,
          from_address: args.fromAddress,
          to_address: args.toAddress,
          list_id: -1, // historical reason, useless now
          card_token: args.cardToken,
        }
      );
      return data;
    },
    /*
      Gkash Payment
    */
    processGkashMint: async (args: GkashMintArgs) => {
      const { data } = await authInstance.post<GkashPaymentDetail>(
        '/launchpad/credit_mint_nft',
        {
          uuid: args.uuid,
          wallet_address: args.walletAddress,
          amount: args.amount,
          phase: args.phase,
          total_price: args.totalPrice,
        }
      );
      return data;
    },
    processGkashBuy: async (args: GkashBuyArgs) => {
      const { data } = await authInstance.post<GkashPaymentDetail>(
        '/marketplace/credit/buy-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress,
          token_id: args.tokenId,
          buyer_address: args.buyerAddress,
          total_price: args.totalPrice,
          list_id: -1, // historical reason, useless now
        }
      );
      return data;
    },
    processGkashBid: async (args: GkashBidArgs) => {
      const { data } = await authInstance.post<GkashPaymentDetail>(
        '/marketplace/credit/bid-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress,
          token_id: args.tokenId,
          price: args.bidPrice,
          total_price: args.totalPrice,
          from_address: args.fromAddress,
          to_address: args.toAddress,
          list_id: -1, // historical reason, useless now
        }
      );
      return data;
    },
    /*
      Market
    */
    createSale: async (args: CreateSaleArgs) => {
      const { data } = await authInstance.post<any>(
        '/marketplace/create-sell-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          token_id: args.tokenId,
          currency_id: args.currencyId,
          seller_address: args.sellerAddress,
          service_fee: args.serviceFee,
          quality: args.quantity, // typo issue
          selling_price: args.sellingPrice,
        }
      );
      return data;
    },
    createAuction: async (args: CreateAuctionArgs) => {
      const { data } = await authInstance.post<any>(
        '/marketplace/create-auction-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          token_id: args.tokenId,
          currency_id: args.currencyId,
          seller_address: args.sellerAddress,
          service_fee: args.serviceFee,
          quality: args.quantity, // typo issue
          fixed_price: 1e18, // useless field
          minimum_bid: args.floorPrice,
          end_date: args.endtimestamp,
          bid_increase_percentage: args.bidIncreasePercentage,
        }
      );
      return data;
    },
    takeHighestBid: async (args: TakeHighestBidArgs) => {
      const { data } = await authInstance.post<any>(
        '/marketplace/credit/accept-bid-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          token_id: args.tokenId,
          list_id: -1, // historical reason, useless now
        }
      );
      return data;
    },
    cancelOrder: async (args: CancelOrderArgs) => {
      const { data } = await authInstance.post<any>(
        '/marketplace/credit/order-cancel',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          token_id: args.tokenId,
          list_id: -1, // historical reason, useless now
        }
      );
      return data;
    },
    getWithdrawNftCode: async (args: WithdrawData) => {
      const { data } = await authInstance.post<any>(
        '/profile/withdraw-nft/get-code',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          token_id: args.tokenId,
          wallet_address: args.receiver?.toLowerCase(),
        }
      );
      return data;
    },
    withdrawNft: async (args: WithdrawNftArgs) => {
      const { data } = await authInstance.post<any>(
        '/profile/withdraw-nft/submit',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          token_id: args.tokenId,
          wallet_address: args.receiver?.toLowerCase(),
          code: args.code,
        }
      );
      return data;
    },
    /*
      Others
    */
    getLoginStatus: async () => {
      const { data: isLogin } = await authInstance.get<true>('/auth/status');
      return isLogin;
    },
    getKycStatus: async () => {
      const { data } = await authInstance.get<KycStatus>('/auth/kyc/status');
      return data;
    },
    getMintStatus: async (uuid: string, address: string) => {
      const { data } = await authInstance.post<MintStatus>('/launchpad/check', {
        wallet_address: address?.toLowerCase(),
        uuid,
      });
      return data;
    },
    getNftQRCode: async (chainId: number, address: string, tokenId: number) => {
      const { data } = await authInstance.post<string>(
        '/event/nft/owner-qrcode',
        {
          chain_id: chainId,
          address: address?.toLowerCase(),
          token_id: tokenId,
        }
      );
      return data;
    },
    getNftEvents: async (chainId: number, address: string, tokenId: number) => {
      const { data } = await authInstance.post<NftEvent[]>(
        '/event/nft/events',
        {
          chain_id: chainId,
          address: address?.toLowerCase(),
          token_id: tokenId,
        }
      );
      return data;
    },
  };

  const publicAPI = {
    /*
      Auth
    */
    emailLogin: async (email: string, password: string) => {
      const { data } = await axios.post<LoginData>('/auth/login', {
        email,
        password,
      });
      return data;
    },
    emailRegister: async (registerData: RegisterData) => {
      const { data } = await axios.post<LoginData>('/auth/register', {
        name: registerData.name,
        email: registerData.email,
        password: registerData.password,
        password_confirmation: registerData.confirmPassword,
        address: registerData.address?.toLowerCase(),
        wallet_key: registerData.privateKey,
      });
      return data;
    },
    isUsernameValid: async (name: string) => {
      const { data: isDuplicate } = await axios.post<boolean>(
        '/auth/check-user',
        { name }
      );
      return isDuplicate;
    },
    /*
      Index
    */
    getBannerList: async () => {
      const { data } = await axios.get<HomeBanner[]>('/system/banner');
      return data;
    },
    getPopularCollection: async (chainId?: number) => {
      const { data } = await axios.get('/collection', {
        params: { chain_id: chainId },
      });
      return data.data;
    },
    getSearchResult: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: GetSearchResultQueryArgs;
      pageParam?: string;
    }) => {
      const { chainId, keyword, category } = queryKey[1];
      const { data } = await axios.post<any>(`/search/${category}`, {
        chain_id: chainId,
        q: keyword,
        cursor: pageParam,
      });
      return data;
    },
    getLiveOrder: async (chainId?: number) => {
      const { data } = await axios.get<any>('/marketplace/live-order', {
        params: { chain_id: chainId },
      });
      return data;
    },
    /*
      Launchpad
    */
    getLaunchpadList: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: [string, { chainId?: number }];
      pageParam?: string;
    }) => {
      const { data } = await axios.get<any>('/launchpad', {
        params: {
          chain_id: queryKey[1].chainId,
          cursor: pageParam,
        },
      });
      return data;
    },
    getLaunchpadDetails: async (uuid: string, chainId?: number) => {
      const { data } = await axios.post<any>('/launchpad/detail', {
        chain_id: chainId,
        uuid,
      });
      return data;
    },
    /*
      Collection
    */
    getCollectionList: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: [string, { chainId?: number }];
      pageParam?: string;
    }) => {
      const { data } = await axios.get<any>('/collection', {
        params: {
          chain_id: queryKey[1].chainId,
          cursor: pageParam,
        },
      });
      return data;
    },
    getCollectionDetails: async (
      collectionAddress: string,
      chainId?: number
    ) => {
      const { data } = await axios.post<CollectionDetail>(
        '/collection/detail',
        {
          address: collectionAddress?.toLowerCase(),
          chain_id: chainId,
        }
      );
      return data;
    },
    getCollectionActivity: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: [string, { chainId?: number; address: string }];
      pageParam?: string;
    }) => {
      const { chainId, address } = queryKey[1];
      const { data } = await axios.post<any>('/collection/activity', {
        chain_id: chainId,
        address: address?.toLowerCase(),
        cursor: pageParam,
      });
      return data;
    },
    getNftList: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: GetNftListQueryArgs;
      pageParam?: string;
    }) => {
      const { chainId, collectionAddress, filter, sort, attributes } =
        queryKey[1];

      const search = attributes
        ?.map(
          (item) =>
            `search[${encodeURIComponent(
              item.category
            )}][]=${encodeURIComponent(item.value)}`
        )
        .join('&');

      const { data } = await axios.get<NFT[]>(
        `/marketplace/order-list?${search}`,
        {
          params: {
            chain_id: chainId,
            collection_address: collectionAddress?.toLowerCase(),
            filter,
            sort,
            cursor: pageParam,
          },
        }
      );
      return data;
    },
    getNftDetails: async (
      tokenId: number,
      collectionAddress: string,
      chainId?: number
    ) => {
      const { data } = await axios.post<NftDetail>(
        '/marketplace/order-detail',
        {
          token_id: tokenId,
          collection_address: collectionAddress?.toLowerCase(),
          chain_id: chainId,
        }
      );
      return data;
    },
    getNftHistory: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: GetNftHistoryQueryArgs;
      pageParam?: string;
    }) => {
      const { chainId, collectionAddress, tokenId } = queryKey[1];
      const { data } = await axios.post<any>('/collection/nft/activity', {
        chain_id: chainId,
        collection_address: collectionAddress?.toLowerCase(),
        token_id: tokenId,
        cursor: pageParam,
      });
      return data;
    },
    getBidHistory: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: [string, { orderId: number }];
      pageParam?: string;
    }) => {
      const { orderId } = queryKey[1];
      const { data } = await axios.get<any>('/marketplace/bid-record', {
        params: {
          order_id: orderId,
          cursor: pageParam,
        },
      });
      return data;
    },
    /*
      User
    */
    getUserCollection: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: [string, { chainId?: number; uuid: string }];
      pageParam?: string;
    }) => {
      const { chainId, uuid } = queryKey[1];
      const { data } = await axios.post<any>('/profile/user_collection', {
        chain_id: chainId,
        uuid,
        cursor: pageParam,
      });
      return data;
    },
    getUserNft: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: GetUserNftQueryArgs;
      pageParam?: string;
    }) => {
      const { chainId, uuid, category, sort, itemsPerPage, search } =
        queryKey[1];
      const { data } = await axios.post<any>('/profile/user_nft', {
        chain_id: chainId,
        uuid,
        category,
        sort,
        limit: itemsPerPage,
        search,
        cursor: pageParam,
      });
      return data;
    },
    getUserActivity: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: [string, { chainId?: number; uuid?: string }];
      pageParam?: string;
    }) => {
      const { chainId, uuid } = queryKey[1];
      const { data } = await axios.post<any>('/profile/activity', {
        chain_id: chainId,
        uuid,
        cursor: pageParam,
      });
      return data;
    },
    /*
      System
    */
    getCurrencyList: async (chainId?: number, isActive = Number(true)) => {
      const { data } = await axios.get<CurrencyData[]>('/currency', {
        params: {
          chain_id: chainId,
          is_active: isActive,
        },
      });
      return data;
    },
    getConfig: async (chainId?: number) => {
      const { data } = await axios.get<Config>('/system/config', {
        params: { chain_id: chainId },
      });
      return data;
    },
    getFAQ: async () => {
      const { data } = await axios.get<FAQ[]>('/system/faq');
      return data;
    },
  };

  // Prevent duplicate key on private and public API (key duplicate will overriding and hard to debug)
  const combine = <
    T extends object,
    U extends object & { [K in keyof T]?: undefined }
  >(
    obj1: T,
    obj2: U
  ) => {
    return {
      ...obj1,
      ...obj2,
    };
  };

  return combine(privateAPI, publicAPI);
};

export { useAPI };
