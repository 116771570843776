import { APIName, Fun } from '@util/api';
import { CHAIN_ID, CONNECTED, USER_DATA } from '@share/constant';
import { ContractType } from '@share/enums';
import { Network } from '@share/interfaces';
import { Web3Provider } from '@ethersproject/providers';
import { createContainer } from 'unstated-next';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce';
import { useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'src/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { user } from './user';

let handleAccountsChanged: (accounts: string[]) => void;
let handleChainChanged: (newChainId: string) => void;

function useWeb3Modal() {
  const [persistChainId, setPersistChainId] = useLocalStorage<number | null>(
    CHAIN_ID,
    null
  );
  const [address, setAddress] = useState<string>();
  const [chainId, setChainId] = useState<number>(
    persistChainId || Number(process.env.REACT_APP_DEFAULT_CHAINID)
  );
  const [chainList, setChainList] = useState<Network[]>();
  const [provider, setProvider] = useState<Web3Provider>();
  const navigate = useNavigate();
  const {
    userData,
    isLogin,
    isNoMetamask,
    setUser,
    setIsLogin,
    updateMetamaskStatus,
  } = user.useContainer();
  const { t } = useTranslation();
  const location = useLocation();

  const storeDefaultChainId = (newId: number) => {
    setChainId(newId);
    setPersistChainId(newId);
  };

  const getMarketAddress = () => {
    const contracts = chainList?.find(
      (item) => item.chain_id === chainId
    )?.contracts;
    return (
      contracts?.find((item) => item.type === ContractType.MARKETPLACE_721)
        ?.address || ethers.ZeroAddress
    );
  };

  const initData = async (addr: string, pro: Web3Provider) => {
    // which means calling this function multiple time will not update address and add listener
    if (address !== addr) {
      setAddress(addr);
      const metaMaskProvider: any = pro?.provider;
      metaMaskProvider?.on('chainChanged', handleChainChanged);
      metaMaskProvider?.on('accountsChanged', handleAccountsChanged);
    }

    const _chainId = (await pro.getNetwork()).chainId; // decimal value
    setChainId(_chainId);
    handleChainChanged(_chainId.toString(16));
  };

  const getProvider = async () => {
    let web3: any;
    const { ethereum } = window as any;
    if (typeof ethereum === 'undefined') {
      toast.error(t('toastMessage.metamaskRequired'));
      return false;
    }
    try {
      web3 = await ethereum.request({ method: 'eth_requestAccounts' });
    } catch (error: any) {
      if (error?.message === 'User rejected the request.') {
        toast.error(t('toastMessage.metamaskLoginRequired'));
      }
      return false;
    }
    if (web3?.length === 0) return false;
    return new Web3Provider(ethereum, 'any');
  };

  const removeCacheConnection = () => {
    localStorage.removeItem(CONNECTED);
    return false;
  };

  const connectWeb3Modal = async (
    redirectLink = '/profile/my'
  ): Promise<boolean> => {
    const _provider = await getProvider();
    if (!_provider) return false;
    setProvider(_provider);

    const _address = await _provider.getSigner().getAddress();
    if (userData != null) {
      initData(_address, _provider);
      return true;
    }

    // Prevent multi login at the same time
    const myNonce = (Math.random() + 1).toString(36).substring(7);
    const serverNonce = await Fun[APIName.POST_GET_NONCE](
      _address.toLowerCase(),
      myNonce
    );

    if (!serverNonce) return removeCacheConnection();
    try {
      const signature = await _provider
        .getSigner()
        .signMessage(serverNonce.toString());

      if (!signature) return removeCacheConnection();

      const response = await Fun[APIName.POST_CONNECT_WALLET](
        _address.toLowerCase(),
        signature,
        myNonce
      );

      if (!response) return false;
      setUser(response);
      setIsLogin(true);
      updateMetamaskStatus(response);
      initData(_address, _provider);
      navigate(redirectLink);
    } catch (error: any) {
      return removeCacheConnection();
    }
    return true;
  };

  const disconnectWeb3Modal = async (redirectLink = '/', state?: any) => {
    const metaMaskProvider: any = provider?.provider;
    if (metaMaskProvider) {
      metaMaskProvider.removeListener('accountsChanged', handleAccountsChanged);
      metaMaskProvider.removeListener('chainChanged', handleChainChanged);
      // removeListener seems not working, so use hacky way to remove listener first
      metaMaskProvider._events = {};
      metaMaskProvider._eventsCount = 0;
    }

    await Fun[APIName.LOGOUT]();
    setIsLogin(false);
    setUser(null);
    setAddress(undefined);
    localStorage.removeItem(CONNECTED);
    localStorage.removeItem(USER_DATA);
    toast.dismiss('wrong-chain');
    navigate(redirectLink, { state });
  };

  const isValidRequest = async (_chainId: number) => {
    if (!isLogin) {
      navigate('/login', { state: { redirectTo: location.pathname } });
      return false;
    }

    if (isNoMetamask) return true;
    if (!address) return connectWeb3Modal();

    if (chainId !== _chainId) {
      toast.error(t('toastMessage.supportedChainRequired'));
      return false;
    }
    return true;
  };

  const getAddress = () => {
    if (isNoMetamask) return userData?.user?.wallet[0].wallet_address || '';
    return address || '';
  };

  // NOTE: Don't use state here since this function is out of react scope,
  // setState will not have any effect
  handleAccountsChanged = (_accounts: string[]) => {
    disconnectWeb3Modal();
  };

  // NOTE: Don't use state here since this function is out of react scope,
  // setState will not have any effect
  // newChainId is hex value
  handleChainChanged = async (newChainId: string) => {
    const newChainIdDec = parseInt(newChainId, 16);
    if (!localStorage.getItem(CONNECTED)) return;
    setChainId(newChainIdDec);
    const chainIdList = chainList?.map((item) => item.chain_id);
    if (!chainIdList?.includes(newChainIdDec)) {
      toast.error(t('toastMessage.supportedChainRequired'), {
        closeOnClick: false,
        closeButton: false,
        autoClose: false,
        hideProgressBar: true,
        position: 'bottom-center',
        draggable: false,
        toastId: 'wrong-chain',
      });
      return;
    }

    toast.dismiss('wrong-chain');
  };

  const getChainName = useMemo(() => {
    return (_chainId: number): string =>
      chainList?.find((item) => item.chain_id === _chainId)?.name ||
      t('header.unsupportedChain');
  }, [chainList]);

  const explorerBaseURL = useMemo(() => {
    return (_chainId: number) =>
      chainList
        ?.find((item) => item.chain_id === _chainId)
        ?.explorers[0]?.url?.replace(/\/+$/, '');
  }, [chainList]);

  const addressExplorerBaseURL = useMemo(() => {
    return (_chainId: number) => `${explorerBaseURL(_chainId)}/address`;
  }, [explorerBaseURL]);

  const transactionExplorerBaseURL = useMemo(() => {
    return (_chainId: number) => `${explorerBaseURL(_chainId)}/tx`;
  }, [explorerBaseURL]);

  const getSupportedChain = async () => {
    const response = await Fun[APIName.GET_LIST_NETWORK](1);
    setChainList(response);
  };

  useEffect(() => {
    getSupportedChain();
  }, []);

  useEffect(() => {
    if (chainList) {
      if (localStorage.getItem(CONNECTED)) {
        connectWeb3Modal();
      }
    }
  }, [chainList]);

  const connect = useDebouncedCallback(connectWeb3Modal, 1000, {
    leading: true,
    trailing: false,
  });

  const disconnect = useDebouncedCallback(disconnectWeb3Modal, 1000, {
    leading: true,
    trailing: false,
  });

  return {
    address,
    chainId,
    chainList,
    provider,
    getMarketAddress,
    getProvider,
    getAddress,
    getChainName,
    storeDefaultChainId,
    addressExplorerBaseURL,
    transactionExplorerBaseURL,
    isValidRequest,
    connect,
    disconnect,
  };
}

export const web3 = createContainer(useWeb3Modal);
