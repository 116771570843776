export * from './api-error-status';

export enum PaymentStatus {
  NEED_AUTHENTICATION = 'NEED_AUTHENTICATION',
  SUCCEED = 'SUCCEED',
  FAILED = 'FAILED',

  // Checkout.com Status
  PENDING = 'Pending',
  AUTHORIZED = 'Authorized',
  VERIFIED = 'Card Verified',
  VOIDED = 'Voided',
  PARTIALLY_CAPTURED = 'Partially Captured',
  CAPTURED = 'Captured',
  PARTIALLY_REFUNDED = 'Partially Refunded',
  REFUNDED = 'Refunded',
  DELINED = 'Declined',
  CANCELED = 'Canceled',
  EXPIRED = 'Expired',
  PAID = 'Paid',
}

export enum BalanceType {
  RECEIVE = 'Receive',
  WITHDRAW = 'Withdraw',
  DEPOSIT = 'Deposit',
  FREEZE = 'Freeze',
  MINT = 'Mint',
  ROYALTY = 'Royalty',
  SERVICE_FEE = 'Service Fee',
}

export const enum ThemeType {
  LIGHT = 'light',
  DARK = 'is_dark',
}

export const enum LanguageType {
  EN = 'en',
  ZH_TW = 'zh-TW',
  ZH_CN = 'zh-CN',
}

export const enum LaunchpadStatus {
  PUBLISH = 'PUBLISH',
  ENDED = 'ENDED',
}

export const enum ContractType {
  MARKETPLACE_721 = 'MARKETPLACE_721',
  COLLECTION_SIGN_CONTRACT_INFO = 'COLLECTION_SIGN_CONTRACT_INFO',
  COLLECTION_CONTRACT_FACTORY = 'COLLECTION_CONTRACT_FACTORY',
  MARKETPLACE_TREASURY_WALLET_CONTRACT = 'MARKETPLACE_TREASURY_WALLET_CONTRACT',
}

export const enum CollectionStatus {
  PENDING = 'PENDING',
  LISTING = 'LISTING',
  HIDDEN = 'HIDDEN',
  BLOCKED = 'BLOCKED',
}

export const enum TokenType {
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
  SOULBOUND = 'SOULBOUND',
}

export const enum PaymentEventType {
  DEPLOY_COLLECTION = 'DEPLOY_COLLECTION',
  MINT_NFT = 'MINT_NFT',
  BUY_NFT = 'BUY_NFT',
  BID_NFT = 'BID_NFT',
}

export const enum NftEventType {
  COUNT = 'COUNT',
  COUNTING_AND_GROUPING = 'COUNTING_AND_GROUPING',
  DISPLAY = 'DISPLAY',
}

export const enum PaymentGatewayType {
  CHECKOUT = 'CHECKOUT',
  GKASH = 'GKASH',
}

export const enum KycStatus {
  APPROVE = 'APPROVE',
  PENDING = 'PENDING',
}

export const enum AssetType {
  IMAGE = 'IMAGE',
  MODEL = 'MODEL',
  VIDEO = 'VIDEO',
  SOUND = 'SOUND',
}
