import { ReactElement } from 'react';
import { cache } from './cache';
import { market } from './market';
import { user } from './user';
import { web3 } from './web3';
/**
 * State providing wrapper
 * @param {ReactElement | ReactElement[]} children to inject
 * @returns {ReactElement} wrapper
 */
export default function StateProvider({
  children,
}: {
  children: ReactElement | ReactElement[];
}): ReactElement {
  return (
    <user.Provider>
      <web3.Provider>
        <market.Provider>
          <cache.Provider>{children}</cache.Provider>
        </market.Provider>
      </web3.Provider>
    </user.Provider>
  );
}
