import { BalanceType } from '@share/enums';
import { useHelper } from './useHelper';
import { useTranslation } from 'react-i18next';
import { web3 } from '@state/web3';
import Badge, { BadgeType } from '@com/utils/Badge';

interface BadgeData {
  text: string;
  badgeType: BadgeType;
}

const useBalance = () => {
  const { t } = useTranslation();
  const { transactionExplorerBaseURL } = web3.useContainer();
  const { displayCurrency } = useHelper();
  const badgeData: Record<any, BadgeData> = {
    [BalanceType.DEPOSIT]: {
      text: t('bankReceipt.revenue'),
      badgeType: BadgeType.SUCCESS,
    },
    [BalanceType.RECEIVE]: {
      text: t('bankReceipt.revenue'),
      badgeType: BadgeType.SUCCESS,
    },
    [BalanceType.SERVICE_FEE]: {
      text: t('bankReceipt.revenue'),
      badgeType: BadgeType.SUCCESS,
    },
    [BalanceType.MINT]: {
      text: t('bankReceipt.revenue'),
      badgeType: BadgeType.SUCCESS,
    },
    [BalanceType.ROYALTY]: {
      text: t('bankReceipt.revenue'),
      badgeType: BadgeType.SUCCESS,
    },
    [BalanceType.FREEZE]: {
      text: t('bankReceipt.freezed'),
      badgeType: BadgeType.WARNING,
    },
    [BalanceType.WITHDRAW]: {
      text: t('bankReceipt.expenditure'),
      badgeType: BadgeType.DANGER,
    },
  };

  const getBadge = (
    type: BalanceType,
    size: 'sm' | 'md' | 'lg'
  ): JSX.Element => {
    return (
      <Badge
        size={size}
        text={badgeData[type]?.text}
        type={badgeData[type]?.badgeType}
      />
    );
  };

  const getTxURL = (chainId?: number, hash?: string) => {
    return hash ? (
      <a
        href={`${transactionExplorerBaseURL(
          chainId || Number(process.env.REACT_APP_DEFAULT_CHAINID)
        )}/${hash}`}
        rel="noreferrer"
        target="_blank"
      >
        {hash}
      </a>
    ) : (
      '-'
    );
  };

  const getCardNumber = (source?: any) => {
    return source?.last4 || source?.last_4
      ? `**** **** **** ${source?.last4 || source?.last_4}`
      : '-';
  };

  const getAmount = (amount?: string, curr?: string) => {
    return amount && curr
      ? // Checkout.com need to / 100
        displayCurrency(Number(amount) / 100, curr)
      : '-';
  };

  const getInvoiceDate = (item?: any) => {
    return item?.created_at
      ? new Date(`${item.created_at}Z`).toLocaleString()
      : '-';
  };

  return { getBadge, getTxURL, getCardNumber, getAmount, getInvoiceDate };
};

export { useBalance };
