import { PaymentEventType, PaymentStatus } from '@share/enums';
import { useHelper } from './useHelper';
import { useTranslation } from 'react-i18next';
import { web3 } from '@state/web3';
import Badge, { BadgeType } from '@com/utils/Badge';
import Spinner from '@com/utils/Spinner';

interface BadgeData {
  text: string;
  badgeType: BadgeType;
}

const useReceipt = () => {
  const { t, i18n } = useTranslation();
  const { transactionExplorerBaseURL } = web3.useContainer();
  const { displayCurrency } = useHelper();
  const badgeData: Record<any, BadgeData> = {
    [PaymentStatus.CAPTURED]: {
      text: t('receipt.paySucceed'),
      badgeType: BadgeType.SUCCESS,
    },
    [PaymentStatus.AUTHORIZED]: {
      text: t('receipt.payAuthorized'),
      badgeType: BadgeType.SUCCESS,
    },
    [PaymentStatus.PENDING]: {
      text: t('receipt.payPending'),
      badgeType: BadgeType.WARNING,
    },
    [PaymentStatus.DELINED]: {
      text: t('receipt.payFailed'),
      badgeType: BadgeType.DANGER,
    },
    [PaymentStatus.VOIDED]: {
      text: t('receipt.payVoided'),
      badgeType: BadgeType.SECONDARY,
    },
  };

  const getBadge = (
    status: PaymentStatus,
    size: 'sm' | 'md' | 'lg'
  ): JSX.Element => {
    return (
      <Badge
        size={size}
        text={badgeData[status]?.text || t('receipt.payFailed')}
        type={badgeData[status]?.badgeType || BadgeType.DANGER}
      />
    );
  };

  const getTxURL = (
    chainId?: number,
    hash?: string,
    status?: PaymentStatus,
    event?: string
  ) => {
    /**
     * For AUTHORIZED case, there have around 10 seconds gap that tx still undefined
     * since backend will change status first and then call web3 service, so keep
     * spinner here, but no spinner for bidding.
     */
    if (
      status === PaymentStatus.PENDING ||
      (event !== PaymentEventType.BID_NFT &&
        status === PaymentStatus.AUTHORIZED)
    )
      return <Spinner />;

    return hash ? (
      <a
        href={`${transactionExplorerBaseURL(
          chainId || Number(process.env.REACT_APP_DEFAULT_CHAINID)
        )}/${hash}`}
        rel="noreferrer"
        target="_blank"
      >
        {hash}
      </a>
    ) : (
      '-'
    );
  };

  const getCardNetwork = (payload?: any) => {
    return payload?.PaymentType ? payload?.PaymentType : '-';
  };

  const getCardNumber = (source?: any) => {
    return source?.last4 || source?.last_4
      ? `**** **** **** ${source?.last4 || source?.last_4}`
      : '-';
  };

  const getAmount = (amount?: string, curr?: string) => {
    return amount && curr
      ? // Checkout.com need to / 100
        displayCurrency(Number(amount) / 100, curr)
      : '-';
  };

  const getInvoiceDate = (data?: any) => {
    // data.payload for checkout payment
    const date =
      data?.payload?.requested_on ||
      data?.payload?.processed_on ||
      data?.created_at;
    return date ? new Date(date).toLocaleString(i18n.language) : '-';
  };

  return {
    getBadge,
    getTxURL,
    getCardNumber,
    getCardNetwork,
    getAmount,
    getInvoiceDate,
  };
};

export { useReceipt };
