import { LEGAL_TENDER } from '@share/constant';
import { ethers } from 'ethers';
import { useAPI } from './useAPI';
import { useMemo } from 'react';
import { useSystemConfig } from './useSystemConfig';

const useCurrency = () => {
  const { getCurrencyList: _getCurrencyList } = useAPI(false);
  const { config } = useSystemConfig();
  const data = config?.currencies;

  const getCurrencyList = useMemo(() => () => data, [data]);

  const getServiceRate = useMemo(() => {
    return (currencyId: number) => {
      return Number(data?.find((item) => item.id === currencyId)?.service_fee);
    };
  }, [data]);

  // Because if currency name is null or undefined, it will be error of toLocaleString function
  // Therefore, using XXX to make sure show currency name first
  // not used real currency symbol because we support multi-network, it will a bit
  // hard to debug if somehow currencyId.name is undefined
  const getCurrencyNameById = useMemo(() => {
    return (currencyId: number) =>
      data?.find((item) => item.id === currencyId)?.name || 'XXX';
  }, [data]);

  const getCurrencyAddressById = useMemo(() => {
    return (currencyId: number) =>
      data?.find((item) => item.id === currencyId)?.address ||
      ethers.ZeroAddress;
  }, [data]);

  const getNativeCurrency = useMemo(() => {
    return () => data?.find((item) => item.address === ethers.ZeroAddress);
  }, [data]);

  const isFiatCurrency = useMemo(() => {
    return (value?: number | string, key: 'id' | 'name' = 'id') =>
      data?.find((item) => item[key] === value)?.address === LEGAL_TENDER;
  }, [data]);

  const getMinimumUnit = (currencyId?: number) => {
    return !currencyId || isFiatCurrency(currencyId, 'id') ? 0.01 : 0.000001;
  };

  return {
    getCurrencyList,
    getCurrencyNameById,
    getCurrencyAddressById,
    getServiceRate,
    getNativeCurrency,
    getMinimumUnit,
    isFiatCurrency,
  };
};

export { useCurrency };
